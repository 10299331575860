import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiTokenService } from "./api-token.service";
import { environment } from "src/environments/environment";
import { Version } from "../constantes.service";
import { Platform } from "@ionic/angular";
import { lastValueFrom, retry } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiPhotoService {
  newAPI;

  constructor(
    private http: HttpClient,
    private apiTokenService: ApiTokenService,
    private platform: Platform,
  ) {
    this.newAPI = environment.newAPI;
  }

  // SUpp jonathan léo le 2021-04-14
  // remis pour envoyer photos une par une léo 27/04/2021
  async envoyerPhoto(file, idMission, avantapres) {
    const headers = await this.apiTokenService.getHeader();
    const aa = avantapres === "avant" ? "315940000" : "315940001";
    const formData = new FormData();
    const device = this.platform.platforms().join();
    const appversion = Version;
    formData.append("file", file, idMission);
    formData.append("missionid", idMission);
    formData.append("avantapres", aa);
    formData.append("device", device);
    formData.append("appversion", appversion);

    return lastValueFrom(
      this.http
        .post(`${this.newAPI}constat/photos`, formData, {
          headers,
          observe: "response",
        })
        .pipe(retry(3)),
    );
  }

  async envoyerSignature(base64, idMission, promoteur) {
    const headers = await this.apiTokenService.getHeader();
    const formData = new FormData();
    const base64Response = await fetch(`data:image/jpeg;base64,${base64}`);
    const file = await base64Response.blob();
    const device = this.platform.platforms().join();
    const appversion = Version;
    formData.append("file", file);
    formData.append("missionid", idMission);
    formData.append("device", device);
    formData.append("appversion", appversion);
    if (promoteur) {
      return lastValueFrom(
        this.http
          .post(`${this.newAPI}signature/promoteur`, formData, { headers })
          .pipe(retry(3)),
      );
    } else {
      return lastValueFrom(
        this.http
          .post(`${this.newAPI}signature/client`, formData, { headers })
          .pipe(retry(3)),
      );
    }
  }

  async deletePhoto(idphoto) {
    const headers = await this.apiTokenService.getHeader();
    return lastValueFrom(
      this.http.delete(this.newAPI + "photo/" + idphoto, { headers }),
    );
  }
}
