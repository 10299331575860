import { Injectable } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { ApiSurveyService } from "./api/api-survey.service";
import { Survey, Visuel, VitrineMission } from "../interfaces/survey";
import { Preferences as Storage } from "@capacitor/preferences";
import { Mission } from "../interfaces/mission";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  constructor(private apiSurveyService: ApiSurveyService) {}

  public async syncSurveys(missions: Mission[]) {
    //get surveys from server
    let newSurveys = await this.getSurveys(missions);

    //save surveys to storage
    await Storage.set({
      key: "surveys",
      value: JSON.stringify(newSurveys),
    });
  }

  public async sendSurveys() {
    //get surveys from storage
    let storage = await Storage.get({ key: "surveys" });
    let surveys: Survey[] = storage.value ? JSON.parse(storage.value) : [];

    //send surveys to server
    for (const survey of surveys.filter((s) => s && !s.synchronised)) {
      await this.sendSurvey(survey);
    }
  }

  //send survey to server
  public async sendSurvey(survey: Survey) {
    this.apiSurveyService.log(JSON.stringify(survey), "Envoi");
    let synchroOk = true;
    if (!survey.synchronised) {
      if (survey.photoPdv?.id && !survey.photoPdv.synchronised) {
        let photopdv = null;
        try {
          photopdv = await Filesystem.readFile({
            path: survey.photoPdv.id,
            directory: Directory.Data,
          });
        } catch (e) {
          this.handleSurveyError(e, "Erreur récupération photo PDV");
          synchroOk = false;
        }
        if (photopdv) {
          await this.apiSurveyService
            .uploadPhoto(survey.photoPdv.id, photopdv.data)
            .catch((err) => {
              this.handleSurveyError(err, "Erreur envoi photo PDV");
              synchroOk = false;
            });
          await this.apiSurveyService
            .updatePhotoPdv(survey.pdvId, survey.photoPdv.id)
            .then(() => {
              survey.photoPdv.synchronised = true;
            })
            .catch((err) => {
              this.handleSurveyError(err, "Erreur mise à jour photo PDV");
              synchroOk = false;
            });
        }
      }
      for (const vue of survey.vues) {
        if (!vue.synchronised) {
          if (vue.new) {
            await this.apiSurveyService
              .createVue(vue)
              .then(() => {
                vue.synchronised = true;
                vue.new = false;
              })
              .catch((err) => {
                this.handleSurveyError(err, "Erreur création vue", vue);
                synchroOk = false;
              });
          } else if (vue.deleted) {
            await this.apiSurveyService
              .deleteVue(vue.id)
              .then(() => {
                survey.vues = survey.vues.filter((v) => v.id !== vue.id);
              })
              .catch((err) => {
                this.handleSurveyError(err, "Erreur suppression vue");
                synchroOk = false;
              });
          } else {
            await this.apiSurveyService
              .updateVue(vue)
              .then(() => {
                vue.synchronised = true;
              })
              .catch((err) => {
                this.handleSurveyError(err, "Erreur mise à jour vue", vue);
                synchroOk = false;
              });
          }
        }
        if (vue.photo?.id && !vue.photo.synchronised) {
          let photovue = null;
          try {
            photovue = await Filesystem.readFile({
              path: vue.photo.id,
              directory: Directory.Data,
            });
          } catch (e) {
            this.handleSurveyError(e, "Erreur récupération photo vue");
            synchroOk = false;
          }
          if (photovue) {
            await this.apiSurveyService
              .uploadPhoto(vue.photo.id, photovue.data)
              .catch((err) => {
                this.handleSurveyError(err, "Erreur envoi photo vue");
                synchroOk = false;
              });
          }
        }
        for (const vitrine of vue.vitrines) {
          if (!vitrine.synchronised) {
            if (vitrine.new) {
              await this.apiSurveyService
                .createVitrine(vitrine)
                .then(() => {
                  vitrine.synchronised = true;
                  vitrine.new = false;
                })
                .catch((err) => {
                  this.handleSurveyError(
                    err,
                    "Erreur création vitrine",
                    vitrine
                  );
                  synchroOk = false;
                });
            } else if (vitrine.deleted) {
              await this.apiSurveyService
                .deleteVitrine(vitrine.id)
                .then(() => {
                  vue.vitrines = vue.vitrines.filter(
                    (v) => v.id !== vitrine.id
                  );
                })
                .catch((err) => {
                  this.handleSurveyError(err, "Erreur suppression vitrine");
                  synchroOk = false;
                });
            } else {
              await this.apiSurveyService
                .updateVitrine(vitrine)
                .then(() => {
                  vitrine.synchronised = true;
                })
                .catch((err) => {
                  this.handleSurveyError(
                    err,
                    "Erreur mise à jour vitrine",
                    vitrine
                  );
                  synchroOk = false;
                });
            }
          }
          if (vitrine.photo?.id && !vitrine.photo.synchronised) {
            let photovitrine = null;
            try {
              photovitrine = await Filesystem.readFile({
                path: vitrine.photo.id,
                directory: Directory.Data,
              });
            } catch (e) {
              this.handleSurveyError(e, "Erreur récupération photo vitrine");
              synchroOk = false;
            }
            if (photovitrine) {
              await this.apiSurveyService
                .uploadPhoto(vitrine.photo.id, photovitrine.data)
                .catch((err) => {
                  this.handleSurveyError(err, "Erreur envoi photo vitrine");
                  synchroOk = false;
                });
            }
          }
        }
      }
      for (const mission of survey.missionsSurvey) {
        if (!mission.synchronised) {
          for (const vitrine of mission.vitrines) {
            if (!vitrine.synchronised) {
              this.copyVitrineData(vitrine, survey);
              if (vitrine.new) {
                await this.apiSurveyService
                  .createVitrineMission(vitrine)
                  .then(async () => {
                    vitrine.synchronised = true;
                    vitrine.new = false;
                  })
                  .catch((err) => {
                    this.handleSurveyError(
                      err,
                      "Erreur création vitrine mission",
                      vitrine
                    );
                    synchroOk = false;
                  });
              } else if (vitrine.deleted) {
                await this.apiSurveyService
                  .deleteVitrineMission(vitrine.id)
                  .then(() => {
                    mission.vitrines = mission.vitrines.filter(
                      (v) => v.id !== vitrine.id
                    );
                  })
                  .catch((err) => {
                    this.handleSurveyError(
                      err,
                      "Erreur suppression vitrine mission"
                    );
                    synchroOk = false;
                  });
              } else {
                await this.apiSurveyService
                  .updateVitrineMission(vitrine)
                  .then(() => {
                    vitrine.synchronised = true;
                  })
                  .catch((err) => {
                    this.handleSurveyError(
                      err,
                      "Erreur mise à jour vitrine mission",
                      vitrine
                    );
                    synchroOk = false;
                  });
              }
            }
          }
          if (synchroOk) {
            await this.apiSurveyService
              .updateStatutMission(mission.missionId, 315940002)
              .then(() => {
                mission.synchronised = true;
                mission.statut = 315940002;
              })
              .catch((err) => {
                this.handleSurveyError(
                  err,
                  "Erreur mise à jour statut mission"
                );
                synchroOk = false;
              });
          }
        }
      }
      survey.synchronised = synchroOk;
      survey.sent = false;

      this.apiSurveyService.log(JSON.stringify(survey), "Fin envoi");

      await this.saveSurvey(survey);
    }
  }

  private handleSurveyError(err: any, reason: string, extra: any = null) {
    console.error(err);

    let message = "";

    if (err instanceof Error) {
      message = err.toString();
    } else {
      message = JSON.stringify(err);
    }
    if (extra) {
      message += `\n\r${JSON.stringify(extra)}`;
    }
    this.apiSurveyService.log(message, reason);
  }

  //get surveys from server
  public async getSurveys(missions: Mission[]) {
    let surveys: Survey[] = [];
    let pdvs = [];
    for (const mission of missions) {
      if (pdvs.indexOf(mission.PDV.accountid) === -1) {
        pdvs.push(mission.PDV);
      }
    }
    for (const pdv of pdvs) {
      let survey = await this.getSurvey(pdv.accountid);
      surveys.push(survey);
    }
    return surveys;
  }

  //get survey from server
  public async getSurvey(pdvId: string) {
    try {
      let photoPdv = await this.fetchPhotoPdv(pdvId);
      let vitrineMissions = await this.fetchVitrinesMission(pdvId);
      let missionsSurvey = [];
      for (const vitrine of vitrineMissions) {
        let mission = missionsSurvey.find(
          (m) => m.missionId === vitrine.missionId
        );
        if (!mission) {
          mission = {
            missionId: vitrine.missionId,
            statut: await this.findStatusSurvey(vitrine.missionId),
            vitrines: [],
            synchronised: true,
          };
          missionsSurvey.push(mission);
        }
        mission.vitrines.push(vitrine);
      }
      let survey: Survey = {
        pdvId: pdvId,
        vues: await this.fetchVues(pdvId),
        missionsSurvey: missionsSurvey,
        synchronised: true,
        photoPdv: photoPdv,
        sent: false,
      };
      return survey;
    } catch (e) {
      console.log("erreur", e);
    }
  }

  //save survey to storage
  public async saveSurvey(survey: Survey) {
    let storage = await Storage.get({ key: "surveys" });
    let surveys: Survey[] = storage.value ? JSON.parse(storage.value) : [];
    let index = surveys.findIndex((s) => s.pdvId === survey.pdvId);
    if (index === -1) {
      surveys.push(survey);
    } else {
      surveys[index] = survey;
    }
    await Storage.set({
      key: "surveys",
      value: JSON.stringify(surveys),
    });

    if (survey.missionsSurvey.length > 0) {
      for (const mission of survey.missionsSurvey) {
        let missions = await Storage.get({ key: "missions" });
        let allMissions: Mission[] = missions.value
          ? JSON.parse(missions.value)
          : [];
        let index = allMissions.findIndex(
          (m) => m.constat.genericAppointment.activityid === mission.missionId
        );
        if (index > -1) {
          let indexMission = allMissions[index];
          indexMission.constat.genericAppointment.fr_surveyafaire =
            mission.statut;
          allMissions[index] = indexMission;
          await Storage.set({
            key: "missions",
            value: JSON.stringify(allMissions),
          });
        }
      }
    }
  }

  //get visuels from server
  public async getVisuels(missions: Mission[]) {
    let campagnes = [];
    for (const mission of missions) {
      if (campagnes.indexOf(mission.Campagne.campaignid) === -1) {
        campagnes.push(mission.Campagne.campaignid);
      }
    }

    for (const campagneid of campagnes) {
      let newVisuels = await this.fetchVisuels(campagneid);
      await this.saveVisuels(newVisuels);
    }
  }

  //save visuels to storage
  public async saveVisuels(visuels: Visuel[]) {
    let storage = await Storage.get({ key: "visuels_campagne" });
    let allVisuels: Visuel[] = storage.value ? JSON.parse(storage.value) : [];
    for (const visuel of visuels) {
      let index = allVisuels.findIndex((v) => v.id === visuel.id);
      if (index === -1) {
        allVisuels.push(visuel);
      } else {
        allVisuels[index] = visuel;
      }
    }
    await Storage.set({
      key: "visuels_campagne",
      value: JSON.stringify(allVisuels),
    });
  }

  //get vitrinesMission from server
  public async fetchVitrinesMission(pdvId: string) {
    let vitrines = await this.apiSurveyService.getVitrinesMission(pdvId);
    let newVitrines: VitrineMission[] = [];
    for (const vitrine of vitrines as any) {
      newVitrines.push({
        id: vitrine.Id,
        name: vitrine.Name,
        quantite: vitrine.Quantite,
        hauteur: this.convertToCentimeters(vitrine.Hauteur),
        largeur: this.convertToCentimeters(vitrine.Largeur),
        profondeur: this.convertToCentimeters(vitrine.Profondeur),
        montantHaut: this.convertToCentimeters(vitrine.MontantHaut),
        montantBas: this.convertToCentimeters(vitrine.MontantBas),
        montantGauche: this.convertToCentimeters(vitrine.MontantGauche),
        montantDroit: this.convertToCentimeters(vitrine.MontantDroit),
        matiere: vitrine.Matiere,
        vitrineId: null,
        vitrineName: vitrine.VitrineName,
        visuelId: vitrine.VisuelId,
        vueId: null,
        vueName: vitrine.VueName,
        missionId: vitrine.MissionId,
        commentaire: vitrine.fr_commentaire,
        new: false,
        deleted: false,
        synchronised: true,
      });
    }
    return newVitrines;
  }

  public async fetchVisuels(campagneid: string) {
    let visuels = await this.apiSurveyService
      .getVisuels(campagneid)
      .catch((err) => console.log(err));

    for (const visuel of visuels) {
      let b64 = visuel.Photo;
      if (b64) {
        await Filesystem.writeFile({
          directory: Directory.Data,
          path: visuel.Id,
          data: b64 as string,
        });
        visuels.push({
          id: visuel.Id,
          campaignid: campagneid,
        });
      }
    }
    return visuels;
  }

  //find status of survey
  public async findStatusSurvey(missionId: string) {
    let missions = await Storage.get({ key: "missions" });
    let allMissions: Mission[] = missions.value
      ? JSON.parse(missions.value)
      : [];
    let index = allMissions.findIndex(
      (m) => m.constat.genericAppointment.activityid === missionId
    );
    return index > -1
      ? allMissions[index].constat.genericAppointment.fr_surveyafaire
      : null;
  }

  //check if survey is pending
  public async hasPendingSurvey(mission: any) {
    try {
      const missionid = mission.constat.genericAppointment.activityid;
      const survey = await this.getSurveyFromStorage(missionid);

      if (!survey) {
        return mission.constat.genericAppointment.fr_surveyafaire === 315940001;
      }

      const missionSurvey = survey?.missionsSurvey.find(
        (m) => m.missionId === missionid
      );

      return (
        (!missionSurvey.synchronised && !survey.sent) ||
        (missionSurvey.synchronised && missionSurvey.statut === 315940001)
      );
    } catch (e) {
      console.error(e);
      this.handleSurveyError(e, "Erreur vérification survey en attente");
      return false;
    }
  }

  private async getSurveyFromStorage(missionId: string) {
    let storage = await Storage.get({ key: "surveys" });
    let surveys: Survey[] = storage.value ? JSON.parse(storage.value) : [];

    return surveys.find((s) =>
      s.missionsSurvey.find((m) => m.missionId === missionId)
    );
  }

  private copyVitrineData(vitrine: VitrineMission, survey: Survey) {
    let targetVitrine = survey.vues
      .map((v) => v.vitrines)
      .flat()
      .find((v) => v.id == vitrine.vitrineId);

    if (!targetVitrine) {
      return;
    }

    vitrine.hauteur = targetVitrine.hauteur;
    vitrine.largeur = targetVitrine.largeur;
    vitrine.profondeur = targetVitrine.profondeur;
    vitrine.montantHaut = targetVitrine.montantHaut;
    vitrine.montantBas = targetVitrine.montantBas;
    vitrine.montantGauche = targetVitrine.montantGauche;
    vitrine.montantDroit = targetVitrine.montantDroit;
    vitrine.name = `${vitrine.vueName} - ${vitrine.vitrineName}`;
  }

  //fetch photoPdv from server
  private async fetchPhotoPdv(pdvId: string) {
    let photoPdv = null;
    let photoPdvId = (await this.apiSurveyService
      .getPhotoPdv(pdvId)
      .catch((err) => console.log(err))) as any;
    if (photoPdvId) {
      let b64 = await this.fetchPhoto(photoPdvId);
      if (b64) {
        photoPdv = {
          id: photoPdvId,
          synchronised: true,
        };
      }
    }
    return photoPdv;
  }

  //fetch vues from server
  private async fetchVues(pdvId: string) {
    let newVues = [];
    let vues = (await this.apiSurveyService
      .getVues(pdvId)
      .catch((err) => console.log(err))) as any;
    for (const vue of vues) {
      try {
        let photo = null;
        if (vue.PhotoId) {
          photo = await this.fetchPhoto(vue.PhotoId);
        }
        let newVue = {
          id: vue.Id,
          name: vue.Name,
          pdvId: vue.PdvId,
          numero: vue.Numero,
          commentaire: vue.Commentaire,
          vitrines: await this.fetchVitrines(vue.Vitrines),
          photo: photo,
          new: false,
          deleted: false,
          synchronised: true,
        };
        newVues.push(newVue);
      } catch (e) {
        console.log("erreur", e);
      }
    }
    return newVues;
  }

  //fetch vitrines from server
  private async fetchVitrines(vitrines: any[]) {
    let newVitrines = [];
    for (const vitrine of vitrines) {
      let photo = null;
      if (vitrine.PhotoId) {
        photo = await this.fetchPhoto(vitrine.PhotoId);
      }
      newVitrines.push({
        id: vitrine.Id,
        name: vitrine.Name,
        vueId: vitrine.VueId,
        pdvId: vitrine.PdvId,
        numero: vitrine.Numero,
        hauteur: this.convertToCentimeters(vitrine.Hauteur),
        largeur: this.convertToCentimeters(vitrine.Largeur),
        profondeur: this.convertToCentimeters(vitrine.Profondeur),
        montantHaut: this.convertToCentimeters(vitrine.MontantHaut),
        montantBas: this.convertToCentimeters(vitrine.MontantBas),
        montantGauche: this.convertToCentimeters(vitrine.MontantGauche),
        montantDroit: this.convertToCentimeters(vitrine.MontantDroit),
        type: vitrine.Type,
        commentaire: vitrine.Commentaire,
        photo: photo,
        new: false,
        deleted: false,
        synchronised: true,
      });
    }
    return newVitrines;
  }

  private convertToCentimeters(value: number) {
    return value ? value / 10 : null;
  }

  //fetch photo from storage or server
  private async fetchPhoto(photoId: string) {
    return new Promise((resolve) => {
      Filesystem.readFile({
        path: photoId,
        directory: Directory.Data,
      })
        .then(() => {
          resolve({
            id: photoId,
            synchronised: true,
          });
        })
        .catch(() => {
          this.apiSurveyService
            .getPhotoSurvey(photoId)
            .then(async (b64) => {
              if (b64) {
                await Filesystem.writeFile({
                  directory: Directory.Data,
                  path: photoId,
                  data: b64 as string,
                });
                resolve({
                  id: photoId,
                  synchronised: true,
                });
              } else {
                resolve(null);
              }
            })
            .catch((err) => {
              console.log(err);
              resolve(null);
            });
        });
    });
  }
}
