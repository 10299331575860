import { Photo } from "./photo";

export enum Etat {
  synchronisee,
  enAttenteEnvoi,
  envoiEnCours,
  erreur,
}

// etat?: string; // synchronisee / enAttenteEnvoi / envoiEnCours / erreur

export interface Appointment {
  ModelesMission: [];
  campaignactivityid: string;
  subject: string;
  activityid: string;
  fr_dateintervention: string;
  fr_datedinterventionproposee: string;
  fr_datelimitederealisation: string;
  fr_datereport: string;
  fr_motifrefus: string;
  fr_motifrefusname: string;
  fr_motifreport: string;
  fr_motifreportname: string;
  fr_prisedephotoapres: number;
  fr_prisedephotoavant: number;
  fr_statut_telephonique: number;
  fr_statut_telephoniquename: string;
  fr_statutmission: number;
  fr_statutmissionname: string;
  missionGenerique: boolean;
  motifsRefus: [];
  motifsReport: [];
  qrs: [
    {
      q_fr_typedereponse;
      q_fr_nquestion;
      q_nbreponsespossibles;
      q_fr_questionpourrefuspdv;
      r_fr_rponseid;
      q_fr_questionobligatoire;
      q_fr_infosquestion;
      q_fr_name;
      r_fr_valeurdelareponse;
      valeursReponse;
      selectedValues;
    },
  ];
  internalState: number;
  photosAvant?: Photo[];
  photosApres?: Photo[];
  etatPhotosAvant?: string;
  etatPhotosApres?: string;
  photos: Photo[];
  fr_commentairedupromoteur: string;
  fr_commentairepourpromoteur: string;
  fr_commentairemotifreport: string;
  fr_commentairemotifsrefus: string;
  fr_responsablerefus: string;
  fr_commentaireappel: string;
  fr_surveyafaire: number;
}

export interface Constat {
  CodeRegroupement: string;
  promoteurId: string;
  pdvid: string;
  campaignid: string;
  EmailPDV: string;
  appointments: Appointment[];
  constatId: string;
  fr_nomresponsablepdvobligatoire: boolean;
  fr_nomresponsablepdv: string;
  fr_signatureobligatoirepdv: boolean;
  fr_signatureobligatoireintervenant: boolean;
  internalState: number;
  nomresponsablepdv: string;
  signatureClient: string;
  signaturePromoteur: string;
  etatSignatureClient?: string; // ajoutee / envoiEnCours / erreur / envoyee
  etatSignaturePromoteur?: string; // ajoutee / envoiEnCours / erreur / envoyee
  genericAppointment: Appointment;
}

export interface Mission {
  // modifieeEtNonSynchro: boolean; //remplacé par etat
  enBrouillon?: boolean;
  etat?: Etat; // synchronisee / enAttenteEnvoi / envoiEnCours / erreur
  type: string;
  id?: number;
  name?: string;
  content?: string;
  distance?: string;
  PDV: {
    name: string;
    accountid: string;
    address1_line1: string;
    address1_line2: string;
    address1_latitude: string;
    address1_longitude: string;
    address1_city: string;
    address1_postalcode: string;
    telephone1: string;
    email: string;
    accountnumber: string;
    fr_ugavuecompteid: string;
    fr_ugavuecomptename: string;
  };
  fr_campagneid: string;
  fr_campagneidname: string;
  fr_dateintervention: string;
  strDateIntervention: string;
  fr_statutmission: number;
  fr_statutmissionname: string;
  activityid: string;
  new_tempsmoyendelaction: string;
  prioritycode: number;
  fr_nomresponsablepdv: string;
  Campagne: {
    campaignid: string;
    name?: string;
    endDate: string;
    startDate: string;
    fr_clientid: string;
    fr_clientname: string;
    fr_codedelacampagnefilrouge: string;
    fr_liencahierdescharges: string;
    fr_lienfichesellout: string;
    fr_azurecdcid: string;
    fr_azurefsoid: string;
    fr_prisederdvobligatoire: boolean;
    new_tg: boolean;
    new_vitrine: boolean;
    new_merch: boolean;
    new_autresdispositifs: boolean;
    typecode: string;
    fr_responsablecampagne: string;
    fr_reportpossibleaprsdatefincampagne: boolean;
  };
  fr_lienods: string;
  constat?: Constat;
  fr_commentairedupromoteur: string;
  fr_commentairepourpromoteur: string;
  repeat: boolean;
  fr_motifreport: string;
  fr_azurefileodsid: string;
  okPhotos: boolean;
  okSignatures: boolean;
}
