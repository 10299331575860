import { Injectable } from "@angular/core";
import {
  LaunchNavigatorOptions,
  LaunchNavigator,
} from "@awesome-cordova-plugins/launch-navigator/ngx";
import { Browser } from "@capacitor/browser";
import { Geolocation } from "@capacitor/geolocation";
import {
  ActionSheetController,
  AlertController,
  Platform,
} from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class GeoLocationService {
  posLat: any;
  posLng: any;

  constructor(
    private launchNavigator: LaunchNavigator,
    private platform: Platform,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController
  ) {
    Geolocation.getCurrentPosition()
      .then((coordinates) => {
        this.posLat = coordinates.coords.latitude;
        this.posLng = coordinates.coords.longitude;
      })
      .catch((err) => {
        console.log("erreur geoloc", err);
        this.posLat = null;
        this.posLng = null;
      });
  }

  // convertit degrés en radian
  deg2rad(x) {
    return (Math.PI * x) / 180;
  }

  // calcule la distance en mètres entre deux points à partir de leurs coordonnées gps
  get_distance_m(lat1, lng1, lat2, lng2) {
    const earthRadius = 6378137; // Terre = sphère de 6378km de rayon
    const rlo1 = this.deg2rad(lng1); // CONVERSION
    const rla1 = this.deg2rad(lat1);
    const rlo2 = this.deg2rad(lng2);
    const rla2 = this.deg2rad(lat2);
    const dlo = (rlo2 - rlo1) / 2;
    const dla = (rla2 - rla1) / 2;
    const a =
      Math.sin(dla) * Math.sin(dla) +
      Math.cos(rla1) * Math.cos(rla2) * (Math.sin(dlo) * Math.sin(dlo));
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadius * d;
  }

  private async presentActionSheet(endLatitude: number, endLongitude: number) {
    let availableApps = await this.launchNavigator.availableApps();
    let availableAppsList = Object.keys(availableApps).filter(
      (app) => availableApps[app]
    );
    if (availableAppsList.length == 0) {
      const alert = await this.alertCtrl.create({
        header: "Erreur",
        message: "Aucune application de navigation n'est installée",
        buttons: ["OK"],
      });
      await alert.present();
      return;
    }
    if (availableAppsList.length == 1) {
      this.navigate(availableAppsList[0], endLatitude, endLongitude);
      return;
    }
    let buttons = [];
    for (let app of availableAppsList) {
      buttons.push({
        text: this.launchNavigator.getAppDisplayName(app),
        handler: () => {
          this.navigate(app, endLatitude, endLongitude);
        },
      });
    }
    this.actionSheetCtrl
      .create({
        header: "Sélectionner une application pour la navigation",
        buttons: [...buttons, { text: "Annuler", role: "cancel" }],
      })
      .then((actionSheet) => {
        actionSheet.present();
      });
  }

  private navigate(app: string, endLatitude: number, endLongitude: number) {
    const options: LaunchNavigatorOptions = {
      start: `${this.posLat},${this.posLng}`,
      app: app,
    };
    this.launchNavigator.navigate([endLatitude, endLongitude], options);
  }

  public openMap(link: string, endLatitude: any, endLongitude: any) {
    if (!this.platform.is("hybrid")) {
      Browser.open({ url: link });
    } else if (this.platform.is("android") || this.platform.is("ios")) {
      this.presentActionSheet(endLatitude, endLongitude);
    }
  }
}
