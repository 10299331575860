import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Events } from "src/app/services/events.service";

@Component({
    selector: "app-modal-synchro",
    templateUrl: "./modal-synchro.component.html",
    styleUrls: ["./modal-synchro.component.scss"],
    standalone: false
})
export class ModalSynchroComponent implements OnInit {
  subscription0;
  subscription1;
  subscription2;
  subscription3;
  subscription4;
  subscription5;
  subscription6;
  subscription7;
  message = "Envoi des missions non synchronisées";
  canHide = false;

  constructor(
    private modalController: ModalController,
    private events: Events,
  ) {}

  ngOnInit() {
    this.subscription0 = this.events.subscribe("debut envoi", (data) => {
      this.message = "Envoi des missions non synchronisées";
    });
    this.subscription1 = this.events.subscribe("envoi en cours", (data) => {
      this.message = data.message;
    });
    this.subscription2 = this.events.subscribe("fin envoi", (data) => {
      this.message = "Récupération des missions";
    });
    this.subscription3 = this.events.subscribe("fin recup missions", (data) => {
      this.canHide = true;
      this.message = "Récupération des fichiers PDF (CDC, ODS, FSO)";
    });
    this.subscription4 = this.events.subscribe("recup fichier", (data) => {
      this.message =
        "Récupération du fichier " +
        data.type +
        " pour la campagne " +
        data.campagne;
    });
    this.subscription5 = this.events.subscribe("fin recup fichiers", (data) => {
      this.message = "Récupération des images";
    });
    this.subscription6 = this.events.subscribe("fin recup photos", (data) => {
      this.message = "Synchronisation des surveys";
    });
    this.subscription7 = this.events.subscribe("dismissSynchro", (data) => {
      this.message = "";
      this.close();
    });
  }

  close() {
    this.subscription0.unsubscribe();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
    this.subscription5.unsubscribe();
    this.subscription6.unsubscribe();
    this.subscription7.unsubscribe();
    this.modalController.dismiss();
  }
}
