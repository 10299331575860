import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginActivate } from "./services/login-activate.service";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./layout/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "connexion",
    loadChildren: () =>
      import("./pages/connexion/connexion.module").then(
        (m) => m.ConnexionPageModule,
      ),
  },
  {
    path: "missions-detail/:id",
    canActivate: [LoginActivate],
    loadChildren: () =>
      import("./pages/missions-detail/missions-detail.module").then(
        (m) => m.MissionsDetailPageModule,
      ),
  },
  {
    path: "missions-filters",
    canActivate: [LoginActivate],
    loadChildren: () =>
      import("./pages/missions-filters/missions-filters.module").then(
        (m) => m.MissionsFiltersPageModule,
      ),
  },
  {
    path: "actions-enattente",
    canActivate: [LoginActivate],
    loadChildren: () =>
      import("./pages/actions-enattente/actions-enattente.module").then(
        (m) => m.ActionsEnattenteModule,
      ),
  },
  {
    path: "survey",
    canActivate: [LoginActivate],
    loadChildren: () =>
      import("./pages/survey/survey.module").then((m) => m.SurveyPageModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule,
      ),
  },
  {
    path: "test",
    loadChildren: () =>
      import("./pages/test/test.module").then((m) => m.TestPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
