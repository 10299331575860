import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { ApiTokenService } from "./api-token.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Version } from "../constantes.service";
import { Vitrine, VitrineMission, Vue } from "../../interfaces/survey";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ApiSurveyService {
  newAPI = environment.newAPI;

  constructor(
    private apiTokenService: ApiTokenService,
    private http: HttpClient,
    private platform: Platform
  ) {}

  public async getPhotoSurvey(photoId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/photo/${photoId}`, {
        headers,
      })
    );
  }

  public async uploadPhoto(photoid: string, photo: any) {
    const headers = await this.apiTokenService.getHeader();
    let formData = new FormData();
    formData.append("photo", photo);
    return await lastValueFrom(
      this.http.post(`${this.newAPI}surveys/photo/${photoid}`, formData, {
        headers,
      })
    );
  }

  public async createVitrine(vitrine: Vitrine) {
    const headers = await this.apiTokenService.getHeader();
    let newVitrine = {
      Id: vitrine.id,
      Name: vitrine.name,
      VueId: vitrine.vueId,
      PdvId: vitrine.pdvId,
      Numero: vitrine.numero,
      Hauteur: this.parseDimension(vitrine.hauteur),
      Largeur: this.parseDimension(vitrine.largeur),
      Profondeur: this.parseDimension(vitrine.profondeur),
      MontantHaut: this.parseDimension(vitrine.montantHaut),
      MontantBas: this.parseDimension(vitrine.montantBas),
      MontantGauche: this.parseDimension(vitrine.montantGauche),
      MontantDroit: this.parseDimension(vitrine.montantDroit),
      Type: vitrine.type,
      PhotoId: vitrine.photo?.id,
      Commentaire: vitrine.commentaire,
    };
    return await lastValueFrom(
      this.http.post(`${this.newAPI}surveys/vitrine`, newVitrine, {
        headers,
      })
    );
  }

  public async updateVitrine(vitrine: Vitrine) {
    const headers = await this.apiTokenService.getHeader();
    let updatedVitrine = {
      Name: vitrine.name,
      Numero: vitrine.numero,
      Hauteur: this.parseDimension(vitrine.hauteur),
      Largeur: this.parseDimension(vitrine.largeur),
      Profondeur: this.parseDimension(vitrine.profondeur),
      MontantHaut: this.parseDimension(vitrine.montantHaut),
      MontantBas: this.parseDimension(vitrine.montantBas),
      MontantGauche: this.parseDimension(vitrine.montantGauche),
      MontantDroit: this.parseDimension(vitrine.montantDroit),
      PhotoId: vitrine.photo?.id,
      Type: vitrine.type,
      Commentaire: vitrine.commentaire,
    };
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}surveys/vitrine/${vitrine.id}`,
        updatedVitrine,
        {
          headers,
        }
      )
    );
  }

  public async deleteVitrine(vitrineId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.delete(`${this.newAPI}surveys/vitrine/${vitrineId}`, {
        headers,
      })
    );
  }

  public async getVues(pdvId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/vues/${pdvId}`, {
        headers,
      })
    );
  }

  public async createVue(vue: Vue) {
    const headers = await this.apiTokenService.getHeader();
    let newVue = {
      Id: vue.id,
      Name: vue.name,
      PhotoId: vue.photo?.id,
      PdvId: vue.pdvId,
      Numero: vue.numero.toString(),
      Commentaire: vue.commentaire,
      Vitrines: [],
    };
    return await lastValueFrom(
      this.http.post(`${this.newAPI}surveys/vue`, newVue, {
        headers,
      })
    );
  }

  public async updateVue(vue: Vue) {
    const headers = await this.apiTokenService.getHeader();
    let updatedVue = {
      Id: vue.id,
      Name: vue.name,
      PhotoId: vue.photo?.id,
      PdvId: vue.pdvId,
      Numero: vue.numero.toString(),
      Commentaire: vue.commentaire,
      Vitrines: [],
    };
    return await lastValueFrom(
      this.http.patch(`${this.newAPI}surveys/vue/${vue.id}`, updatedVue, {
        headers,
      })
    );
  }

  public async deleteVue(vueId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.delete(`${this.newAPI}surveys/vue/${vueId}`, {
        headers,
      })
    );
  }

  public async getPhotoPdv(pdvId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/photoPdv/${pdvId}`, {
        headers,
      })
    );
  }

  public async updatePhotoPdv(pdvId: string, photoId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}surveys/photoPdv/${pdvId}`,
        { photoId },
        { headers }
      )
    );
  }

  public async getVisuels(campagneid: string): Promise<any> {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/visuels/${campagneid}`, {
        headers,
      })
    );
  }

  public async getPhotoVisuel(visuelid: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/photoVisuel/${visuelid}`, {
        headers,
      })
    );
  }

  public async getVitrinesMission(pdvId: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/vitrinesMission/${pdvId}`, {
        headers,
      })
    );
  }

  public async createVitrineMission(vitrine: VitrineMission) {
    const headers = await this.apiTokenService.getHeader();
    let newVitrine = {
      Id: vitrine.id,
      Name: vitrine.name,
      Quantite: vitrine.quantite,
      Matiere: vitrine.matiere,
      Hauteur: this.parseDimension(vitrine.hauteur),
      Largeur: this.parseDimension(vitrine.largeur),
      Profondeur: this.parseDimension(vitrine.profondeur),
      MontantHaut: this.parseDimension(vitrine.montantHaut),
      MontantBas: this.parseDimension(vitrine.montantBas),
      MontantGauche: this.parseDimension(vitrine.montantGauche),
      MontantDroit: this.parseDimension(vitrine.montantDroit),
      VitrineName: vitrine.vitrineName,
      VueName: vitrine.vueName,
      VisuelId: vitrine.visuelId,
      MissionId: vitrine.missionId,
      Commentaire: vitrine.commentaire,
    };
    return await lastValueFrom(
      this.http.post(`${this.newAPI}surveys/vitrineMission`, newVitrine, {
        headers,
      })
    );
  }

  public async updateVitrineMission(vitrine: VitrineMission) {
    const headers = await this.apiTokenService.getHeader();
    let updatedVitrine = {
      Name: vitrine.name,
      Quantite: vitrine.quantite,
      Matiere: vitrine.matiere,
      Hauteur: this.parseDimension(vitrine.hauteur),
      Largeur: this.parseDimension(vitrine.largeur),
      Profondeur: this.parseDimension(vitrine.profondeur),
      MontantHaut: this.parseDimension(vitrine.montantHaut),
      MontantBas: this.parseDimension(vitrine.montantBas),
      MontantGauche: this.parseDimension(vitrine.montantGauche),
      MontantDroit: this.parseDimension(vitrine.montantDroit),
      VitrineId: vitrine.vitrineId,
      VueName: vitrine.vueName,
      VitrineName: vitrine.vitrineName,
      VisuelId: vitrine.visuelId,
      Commentaire: vitrine.commentaire,
    };
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}surveys/vitrineMission/${vitrine.id}`,
        updatedVitrine,
        {
          headers,
        }
      )
    );
  }

  public async deleteVitrineMission(id: string) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.delete(`${this.newAPI}surveys/vitrineMission/${id}`, {
        headers,
      })
    );
  }

  public async updateStatutMission(missionId: string, statut: number) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}surveys/mission/${missionId}/status/${statut}`,
        {},
        { headers }
      )
    );
  }

  public async log(message: any, type: string) {
    const logs = {
      appVersion: Version,
      device: this.platform.platforms().join(),
      type: "Survey - " + type,
      message: message,
    };
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.post(`${this.newAPI}Logs`, logs, { headers })
    );
  }

  private parseDimension(dimension: number | null): number | null {
    return dimension
      ? Math.round(parseFloat(dimension.toString().replace(",", ".")) * 10)
      : null;
  }
}
