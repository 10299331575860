import { Component, NgZone } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { NavigationEnd, Router } from "@angular/router";
import { MissionService } from "./services/mission.service";
import { AlertButton, AlertController } from "@ionic/angular";
import { Events } from "./services/events.service";
import { Preferences, Preferences as Storage } from "@capacitor/preferences";
import { NetworkService } from "./services/network.service";
import { Version } from "src/app/services/constantes.service";
import { AuthService } from "./services/auth.service";
import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";
import { SurveyService } from "./services/survey.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  standalone: false,
})
export class AppComponent {
  url: any;
  nbAEnvoyer = 0;
  version: string;

  constructor(
    private router: Router,
    protected missionService: MissionService,
    private alertCtrl: AlertController,
    protected authService: AuthService,
    protected networkService: NetworkService,
    private surveyService: SurveyService,
    private zone: NgZone,
    private events: Events
  ) {
    this.url = router.url;
    this.version = Version;
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
      }
    });
    this.events.subscribe("changeConstatsAEnvoyer", async (value) => {
      await Storage.set({
        key: "fr_aenvoyer",
        value: value + "",
      });
      this.zone.run(() => {
        this.nbAEnvoyer = value;
      });
    });
    Storage.get({
      key: "fr_aenvoyer",
    }).then((data) => {
      this.zone.run(() => {
        this.nbAEnvoyer = data.value ? parseInt(data.value) : 0;
      });
    });
    this.networkService.statusChanged.subscribe(async (connected) => {
      if (connected && !(await this.missionService.hasPendingMissions())) {
        await this.surveyService.sendSurveys();
      }
    });
    this.initializeApp().then(() => {
      this.authService.loggedIn().then((isLoggedIn) => {
        Storage.get({
          key: "last_sync",
        }).then((data) => {
          if (data.value) {
            const last_sync = new Date(data.value);
            const now = new Date();
            const diff = now.getTime() - last_sync.getTime();
            const diffHours = diff / (1000 * 3600);
            if (diffHours > 24 && isLoggedIn && this.networkService.isOnline) {
              this.missionService.presentSyncroModal();
            }
          } else if (isLoggedIn && this.networkService.isOnline) {
            this.missionService.presentSyncroModal();
          }
          Storage.set({
            key: "last_sync",
            value: new Date().toISOString(),
          });
        });
      });
    });
  }

  async initializeApp() {
    await SplashScreen.hide();
    this.checkForUpdates();
  }

  async checkForUpdates() {
    if (
      Capacitor.getPlatform() !== "android" &&
      Capacitor.getPlatform() !== "ios"
    ) {
      return;
    }

    try {
      let result = await AppUpdate.getAppUpdateInfo();

      const currentVersion =
        Capacitor.getPlatform() === "android"
          ? result.currentVersionCode
          : result.currentVersionName;

      // Get the stored version from the preferences
      const { value: storedVersion } = await Preferences.get({
        key: "appVersion",
      });

      // If the stored version is different from the current version, reset the updateCancelCount
      if (storedVersion !== currentVersion) {
        await Preferences.set({ key: "updateCancelCount", value: "0" });
        await Preferences.set({ key: "appVersion", value: currentVersion });
      }

      if (
        result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return;
      }

      // Get the current count from the preferences
      let { value } = await Preferences.get({ key: "updateCancelCount" });
      let updateCancelCount = value ? parseInt(value) : 0;
      let buttons: AlertButton[] = [
        {
          text: "Mettre à jour",
          handler: () => {
            AppUpdate.openAppStore();
          },
        },
      ];

      if (updateCancelCount < 3) {
        buttons.unshift({
          text: "Annuler",
          role: "cancel",
          handler: () => {
            // Increment the counter when the user cancels the update
            updateCancelCount++;
            Preferences.set({
              key: "updateCancelCount",
              value: updateCancelCount.toString(),
            });
          },
        });
      }

      this.alertCtrl
        .create({
          header: "Nouvelle version disponible",
          message: "Une nouvelle version de l'application est disponible.",
          buttons: buttons,
        })
        .then((alert) => alert.present());
    } catch (e) {
      this.alertCtrl
        .create({
          header: "Erreur",
          message: "Impossible de vérifier les mises à jour: " + e,
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
    }
  }

  async synchroniser() {
    if (this.networkService.isOnline) {
      if (!this.missionService.syncing) {
        this.missionService.syncing = true;
        await this.missionService.presentSyncroModal();
        this.missionService.syncing = false;
      }
    } else {
      this.alertCtrl
        .create({
          message: "Vous devez être connecté à internet pour synchroniser",
          buttons: ["OK"],
        })
        .then((alert) => {
          alert.present();
        });
    }
  }

  logout() {
    this.alertCtrl
      .create({
        message: "Voulez-vous vous déconnecter ?",
        buttons: [
          {
            text: "Non",
            handler: () => {
              console.log("annuler");
            },
          },
          {
            text: "Oui",
            handler: async () => {
              await Storage.remove({ key: "filrouge_user" });
              await Storage.remove({ key: "id_token_filrouge2new" });
              await Storage.remove({ key: "fr_aenvoyer" });
              await Storage.remove({ key: "last_sync" });
              await Storage.remove({ key: "filrouge_delete" });
              await Storage.remove({ key: "missions" });
              await Storage.remove({ key: "fr_list_fichiers" });
              await Storage.remove({ key: "fr_images_registered" });
              await this.router.navigate(["/connexion"]);
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
}
