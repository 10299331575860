<ion-app>
  <ion-menu #menu contentId="main-content">
    <ion-header>
      <ion-toolbar class="menu">
        <ion-grid class="ion-margin-start">
          <ion-row>
            <ion-col size="2">
              <ion-icon name="person-circle-outline" size="large"></ion-icon>
            </ion-col>
            <ion-col>
              <div class="bold">{{ authService.user?.firstname }}</div>
              <div>{{ authService.user?.email }}</div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content scroll-y="false">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <a
            class="ion-text-center ion-padding-bottom"
            href="https://extranetprestataire.filrouge.com"
            >Extranet</a
          >
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button
              (click)="menu.close()"
              [disabled]="!networkService.isOnline || missionService.syncing"
              [routerLink]="['/actions-enattente']"
              class="menu"
              expand="block"
            >
              <ion-icon name="alert-circle-sharp"></ion-icon>
              Actions en attente
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button class="menu" disabled="true" expand="block">
              <ion-icon name="desktop-outline"></ion-icon>
              E-learning
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button class="menu" disabled="true" expand="block">
              <ion-icon name="pie-chart"></ion-icon>
              Mes statistiques
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button class="menu" disabled="true" expand="block">
              <ion-icon name="logo-euro"></ion-icon>
              <ion-label>Ma facturation</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button class="menu" disabled="true" expand="block">
              <ion-icon name="chatbubbles"></ion-icon>
              Nous contacter
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="12">
            <ion-button class="menu" disabled="true" expand="block">
              <ion-icon name="people"></ion-icon>
              Microsoft Teams
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-margin-top">
          <ion-col size="auto">
            <ion-button (click)="menu.close(); logout()" class="logout">
              Déconnexion
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center footer">
          <ion-col size="12">
            <div class="ion-text-center">Version {{ version }}</div>
            <p class="small ion-text-center">
              En cas de problème vous pouvez contacter notre support de 9h30 à
              17h30 au 01 84 20 13 39
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-menu>
  <div class="ion-page" id="main-content">
    <ion-header
      *ngIf="
        url &&
        (url === '/' ||
          url === '/missions-new' ||
          url.startsWith('/missions-lst/') ||
          url === '/forgot-password' ||
          url.startsWith('/missions-detail') ||
          url === '/actions-enattente' ||
          url.startsWith('/survey') ||
          url === '/missions-map')
      "
    >
      <ion-toolbar class="main">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <ion-buttons>
                <ion-menu-button color="light"></ion-menu-button>
              </ion-buttons>
            </ion-col>
            <ion-col size="6" size-lg="4" size-md="5" size-xl="3">
              <ion-img src="assets/imgs/logo_blanc.svg" />
            </ion-col>
            <ion-col class="ion-align-items-center">
              <div *ngIf="url.startsWith('/missions-lst/2') || url === '/'">
                <ion-img
                  (click)="synchroniser()"
                  class="sync"
                  src="assets/icon/sync.svg"
                >
                </ion-img>
                <ion-badge *ngIf="nbAEnvoyer > 0" class="sync"
                  >{{ nbAEnvoyer }}
                </ion-badge>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content scroll-y="false">
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </div>
</ion-app>
