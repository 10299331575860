import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Preferences as Storage } from "@capacitor/preferences";
import { environment } from "../../environments/environment";
import { ToolsService } from "./tools.service";
import { ApiTokenService } from "./api/api-token.service";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user;
  newAPI;

  constructor(
    private http: HttpClient,
    private tools: ToolsService,
    private apiTokenService: ApiTokenService,
  ) {
    this.newAPI = environment.newAPI;
  }

  loginNEW(email, password) {
    return new Promise((resolve, reject) => {
      lastValueFrom(
        this.http.post(this.newAPI + "intervenant/login", {
          UserName: email,
          Password: password,
        }),
      ).then(
        (data) => {
          this.resetToken2(data["access_token"])
            .then((res) => {
              this.user = data;
              Storage.set({
                key: "filrouge_user",
                value: JSON.stringify(data),
              }).then(() => {
                resolve(data);
              });
            })
            .catch((err) => {
              reject(err);
            });
        },
        (err) => {
          console.log(err);
          reject(err);
        },
      );
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      lastValueFrom(
        this.http.post(this.newAPI + "intervenant/lostpassword", { email }),
      ).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject(err);
        },
      );
    });
  }

  async logout() {
    await Storage.remove({
      key: "id_token_filrouge2new",
    });
    await Storage.remove({
      key: "filrouge_user",
    });
    this.user = null;
    await this.apiTokenService.resetToken();
    return true;
  }

  loggedIn() {
    return Storage.get({
      key: "id_token_filrouge2new",
    }).then((token) => {
      return this.getUser().then((user) => {
        if (user && user["expire_on"]) {
          const expiredOn = user["expire_on"];
          const today = new Date().toISOString().split(".")[0];
          return new Date(expiredOn).getTime() > new Date(today).getTime();
        } else {
          return token.value;
        }
      });
    });
  }

  resetToken2(token) {
    return new Promise((resolve, reject) => {
      Storage.set({
        key: "id_token_filrouge2new",
        value: token,
      }).then(() => {
        resolve(true);
      });
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        Storage.get({
          key: "filrouge_user",
        }).then((user) => {
          this.user = JSON.parse(user.value);
          resolve(this.user);
        });
      }
    });
  }
}
