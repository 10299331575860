import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ApiTokenService } from "./api-token.service";
import { Version } from "../constantes.service";
import { Platform } from "@ionic/angular";
import { lastValueFrom, retry } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiMissionService {
  newAPI;

  constructor(
    private http: HttpClient,
    private apiTokenService: ApiTokenService,
    private platform: Platform,
  ) {
    this.newAPI = environment.newAPI;
  }

  public async getMissionsRealiseesApi() {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}constats/realisees`, {
        headers,
      }),
    );
  }

  public async getMissionsAPlanifierApi() {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}constats/aplanifier`, {
        headers,
      }),
    );
  }

  public async getMissionsOffline() {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}constats/offline`, {
        headers,
      }),
    );
  }

  async sendConstat(constat) {
    constat.log = {
      appVersion: Version,
      IP: null,
      device: this.platform.platforms().join(),
    };
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http
        .post(`${this.newAPI}constat/intervenant/new`, constat, {
          headers,
          observe: "response",
        })
        .pipe(retry(3)),
    );
  }

  async forceRealisee(activityid) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}constat/${activityid}/status/realise`,
        {},
        {
          headers,
        },
      ),
    );
  }

  async sendConstatNew(constat) {
    constat.log = {
      appVersion: Version,
      IP: null,
      device: this.platform.platforms().join(),
    };
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http
        .post(`${this.newAPI}constat/generique`, constat, {
          headers,
          observe: "response",
        })
        .pipe(retry(3)),
    );
  }

  async getFichier(id) {
    try {
      const headers = await this.apiTokenService.getHeader();
      return await lastValueFrom(
        this.http.get(`${this.newAPI}document/${id}`, {
          headers,
          responseType: "blob",
        }),
      );
    } catch (e) {
      this.log("erreur récupération d'un document", JSON.stringify(e));
    }
  }

  async getPhoto(id) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}photo/${id}`, {
        headers,
        responseType: "blob",
      }),
    );
  }

  // Normale = 0. HorsFichier = 1. Prisedaccord = 2.
  async getListCampagnes(typeliste) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}type/${typeliste}/campaigns`, {
        headers,
      }),
    );
  }

  async getListPdv(campaignId, fullset = false) {
    const headers = await this.apiTokenService.getHeader();
    if (fullset) {
      return await lastValueFrom(
        this.http.get(`${this.newAPI}campaign/${campaignId}/uga`, {
          headers,
        }),
      );
    } else {
      return await lastValueFrom(
        this.http.get(`${this.newAPI}campaign/${campaignId}/pdvs`, {
          headers,
        }),
      );
    }
  }

  async envoyerBrouillon(constat) {
    constat.log = {
      appVersion: Version,
      IP: null,
      device: this.platform.platforms().join(),
    };
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.post(`${this.newAPI}constat/draft`, constat, {
        headers,
        observe: "response",
      }),
    );
  }

  async updatePlanification(actitityid, date, statuttel, rdvobligatoire) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}constat/${actitityid}/planification/statut/${statuttel}/priserdv/${rdvobligatoire}/date/${date}`,
        {},
        {
          headers,
          observe: "response",
        },
      ),
    );
  }

  async updateStatutTelephonique(actitityid, statut) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.patch(
        `${this.newAPI}constat/${actitityid}/statuttelephonique/${statut}`,
        {},
        {
          headers,
          observe: "response",
        },
      ),
    );
  }

  async getSignature(idSignature) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}signature/${idSignature}`, {
        responseType: "blob",
        headers,
      }),
    );
  }

  async getListStatutsTelephoniques() {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}picklists/statutstelephoniques`, {
        headers,
      }),
    );
  }

  async getResponsableCampagneInfos(userid) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}campaign/responsable/${userid}`, {
        headers,
      }),
    );
  }

  async log(type, message) {
    const logs = {
      appVersion: Version,
      device: this.platform.platforms().join(),
      type: "APPLOG " + type,
      message,
    };
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.post(`${this.newAPI}Logs`, logs, { headers }),
    );
  }

  async getPhotoPDV(photoid: any) {
    const headers = await this.apiTokenService.getHeader();
    return await lastValueFrom(
      this.http.get(`${this.newAPI}surveys/photo/${photoid}`, {
        headers,
      }),
    );
  }
}
